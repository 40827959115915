import {
  MDBSelect,
  MDBSelectInput,
  MDBSelectOption,
  MDBSelectOptions,
  MDBBtn,
  MDBTooltip,
  MDBTableHead,
  MDBIcon
} from "mdbreact";
import React, { useEffect, useState } from "react";
import _, { get } from "lodash";
import http from "../../../../services/http";
import { mapRestrictionToLabel, renderRestrictionEdit } from "../helpers";
import TestModal from "../TestModal";
import { useSnackbar } from "react-simple-snackbar";
import { failureOptions } from "../../../../configs";
import WebRow from "../WebRow";

const currencies = [
  {
    name: "Eur",
    value: 1,
  },
  {
    name: "Rub",
    value: 2,
  },
  {
    name: "Usd",
    value: 0,
  },
];

const TableRow = ({ row: data, landings, getInfo, operators, onCopyClick, actions, newRestrictions, subRestrictions, selectedUserId }) => {
  const [isEditable, setEditable] = useState(false);
  const [isTestModalOpen, setTestModalOpen] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  const [openFailureSnackbar] = useSnackbar(failureOptions)

  const geoOperators = [
    { value: 0, text: "=" },
    { value: 1, text: "Not" },
    { value: 6, text: "In list" },
    { value: 7, text: "Not in list" },
  ];

  const ageOperators = [
    { value: 0, text: "=" },
    { value: 2, text: ">" },
    { value: 3, text: ">=" },
    { value: 4, text: "<" },
    { value: 5, text: "<=" },
  ];

  const [row, setRow] = useState(data);
  const [restrictions, setRestrictions] = useState([]);

  
  useEffect(() => {
    setRow(data);
    setRestrictions(data.restrictions);
  }, [data]);


  const onFieldChange = (value, innerProp, isDeep = false, outerProp = "") => {
    if (isDeep) {
      setRow({
        ...row,
        [outerProp]: {
          ...row[outerProp],
          [innerProp]: value,
        },
      });
      return;
    }
    setRow({
      ...row,
      [innerProp]: value,
    });
  };

  const onRestrictionChange = (type, property, value) => {
    const newRestrictions = [...restrictions];
    const findRestrictionIndex = newRestrictions.findIndex(
      (r) => r.propertyName === type
    );
    if (findRestrictionIndex > -1) {
      newRestrictions[findRestrictionIndex][property] = value;
      setRestrictions([...newRestrictions]);
    } else {
      setRestrictions([
        ...newRestrictions,
        {
          propertyName: type,
          [property]: value,
        },
      ]);
    }
  };

  const clearRestriction = (type) => {
    const newRestrictions = restrictions.filter((r) => r.propertyName !== type);
    setRestrictions(newRestrictions);
  };

  const onEditClick = () => {
    setEditable(true);
  };

  const saveRestrictions = async () => {
    let deleted = [];
    let changed = [];
    let added = [];

    const oldResctrictions = [...data.restrictions];
    const newRestrictions = [...restrictions];

    if (newRestrictions.length > oldResctrictions.length) {
      added = _.differenceBy(newRestrictions, oldResctrictions, "propertyName");
      changed = _.differenceBy(newRestrictions, added, "propertyName");
    }
    if (newRestrictions.length < oldResctrictions.length) {
      deleted = _.differenceBy(
        oldResctrictions,
        newRestrictions,
        "propertyName"
      );
      changed = _.differenceBy(newRestrictions, added, "propertyName");
    }
    if (
      newRestrictions.length === oldResctrictions.length &&
      newRestrictions.length
    ) {
      changed = newRestrictions;
    }
    const addedPromises = added.map((restriction) => {
      return http.post(`/partnerdeals/${row.id}/restrictions`, {
        propertyName: restriction.propertyName,
        propertyValue: restriction.propertyValue,
        propertyOperator: restriction.propertyOperator,
      });
    });
    const deletedPromises = deleted.map((restriction) => {
      return http.delete(
        `/partnerdeals/${row.id}/restrictions/${restriction.id}`
      );
    });
    const changedPromises = changed.map((restriction) => {
      return http.patch(
        `/partnerdeals/${row.id}/restrictions/${restriction.id}`,
        {
          propertyName: restriction.propertyName,
          propertyValue: restriction.propertyValue,
          propertyOperator: restriction.propertyOperator,
        }
      );
    });
    await Promise.all([
      ...addedPromises,
      ...deletedPromises,
      ...changedPromises,
    ]);
  };
  const onSavePress = async () => {
    setEditable(false);
    const body = {
      siteId: row.siteId,
      actionTypeId: actions.find((a) => a.name === row.actionType.name).id,
      partnerLinkId: row.partnerLink.id,
      postback: row.postback,
      payout: +row.payout,
      currency: currencies.find((c) => c.name === row.currencyName)?.value,
      isActive: row.isActive === "true" || row.isActive === true ? true : false,
      siteLandingId: row.siteLandingId ? landings.find((l) => l.url == row.siteLandingId)?.id : landings.find((l) => l.url == row.siteLanding?.url)?.id,
      dailyLimit: +row.dailyLimit,
    };
    const data = await http.patch(`/partnerdeals/${row.id}`, body);
    if (!data) {
      openFailureSnackbar('One or more validation errors occurred.');
    }
    await saveRestrictions();
    getInfo();
  };

  const onTestOpen = () => {
    setTestModalOpen(true);
  };

  const onCancel = () => {
    setRow(data);
    setEditable(false);
    setRestrictions(data.restrictions);
  };

  const formatLandings = () => {
    if (!landings.length) return [];
    return landings.map((l) => ({
      name: l.url,
      value: l.url,
    }));
  };

  const getSitelanding = () => {
    if (!row.siteLanding) {
      return landings[0]?.url || null;
    } else {
      return row.siteLanding.url;
    }
  };

  const affLink = `${getSitelanding()}?utm_source=${
    row.partnerLink.name
  }&linkid=${row.partnerLink.uid}&payout=${
    row.payout
  }&clickid={clickid}&web_id={web_id}&sub_id={sub_id}`;

  return (
    <React.Fragment>
      <TestModal
        visible={isTestModalOpen}
        onClose={() => setTestModalOpen(false)}
        onSave={onSavePress}
        actionType={row.actionType.name}
      />
      {!isEditable ? (
        <>
        <tr style={{ cursor: 'pointer' }} onClick={() => setIsExpanded(!isExpanded)}>
          {/* UUID */}
          <td>
            <MDBIcon icon={isExpanded ? 'angle-up' : 'angle-down'} color='black' size='lg' />
            {row.partnerLink.uid}
            </td>
          {/* LinkName */}
          <td>{row.partnerLink.name}</td>
          {/* ActionType */}
          <td>{row.actionType.name}</td>
          {/* Landing */}
          {/* <td style={{ width: 250 }}>{getSitelanding()}</td> */}
          {/* Afflink */}
          <td style={{ width: 150 }}>
            <MDBTooltip domElement>
              <a
                onClick={() => window.navigator.clipboard.writeText(affLink)}
                style={{ whiteSpace: "pre " }}
              >
                <i className="fa fa-solid fa-copy mr-1"></i>
                <br />
                {affLink.slice(0, 20)}...
              </a>
              <span>{affLink}</span>
            </MDBTooltip>
          </td>
          {/* Postback */}
          <td style={{ width: 200 }}>
            {row.postback ? (
              <MDBTooltip domElement>
                <a
                  onClick={() =>
                    window.navigator.clipboard.writeText(row.postback)
                  }
                  style={{ whiteSpace: "pre " }}
                >
                  <i className="fa fa-solid fa-copy mr-1"></i>
                  <br />
                  {row.postback.slice(0, 20)}...
                </a>
                <span>{row.postback}</span>
              </MDBTooltip>
            ) : null}
          </td>
          {/* Payout */}
          <td>{row.payout}</td>
          {/* Daily Limit */}
          <td>{row.dailyLimit || null}</td>
          {/* Restrictions */}
          <td>
            {restrictions.map((restriction) => (
              <div key={restriction.propertyName}>
                {mapRestrictionToLabel[restriction.propertyName] ?? restriction.propertyName}{" "}
                {operators.find((o) => o.value === restriction.propertyOperator)
                  ?.text || ""}{" "}
                {restriction.propertyValue}
              </div>
            ))}
          </td>
          {/* Currency */}
          {/* <td>{row.currencyName}</td> */}
          {/* isActive */}
          <td>{`${row.isActive}`}</td>
          <td>
          <div onClick={() => onEditClick(row.siteId)}>
            <MDBIcon icon="pen" size="1x" />
          </div>
          <div onClick={() => onCopyClick(row)}>
            <MDBIcon icon="copy" size="1x" />
          </div>
            {/* <MDBBtn size="sm" onClick={() => onEditClick(row.siteId)}>
              Edit
            </MDBBtn>
            <MDBBtn size="sm" onClick={() => onCopyClick(row)}>
              Copy
            </MDBBtn> */}
          </td>
        </tr>
        {isExpanded && <WebRow
          refetch={() => getInfo(true)}
          webs={row.webs}
          newRestrictions={newRestrictions}
          subRestrictions={subRestrictions}
          operators={operators}
          selectedUserId={selectedUserId}
          partnerDealId={row.id}
          />}
        </>
      ) : (
        <tr>
          {/* UUID */}
          <td>{row.partnerLink.uid}</td>
          {/* LinkName */}
          <td>{row.partnerLink.name}</td>
          {/* ActionType */}
          <td>
            <MDBSelect
              getTextContent={(val) =>
                onFieldChange(val, "name", true, "actionType")
              }
              className="colorful-select dropdown-primary mx-2"
            >
              <MDBSelectInput selected={row.actionType.name} />
              <MDBSelectOptions>
                {actions.map((action) => (
                  <MDBSelectOption key={action.id} value={action.name}>
                    {action.name}
                  </MDBSelectOption>
                ))}
              </MDBSelectOptions>
            </MDBSelect>
          </td>
          {/* Landing */}
          <td style={{ minWidth: 250 }}>
            <MDBSelect
              getTextContent={(val) => onFieldChange(val, "siteLandingId")}
              className="colorful-select dropdown-primary mx-2"
            >
              <MDBSelectInput selected={getSitelanding()} />
              <MDBSelectOptions>
                {formatLandings().map((action) => (
                  <MDBSelectOption key={action.id} value={action.name}>
                    {action.name}
                  </MDBSelectOption>
                ))}
              </MDBSelectOptions>
            </MDBSelect>
          </td>
          {/* Afflink */}
          {/* <td style={{ width: 150 }}>{`${getSitelanding()}?utm_source=${
            row.partnerLink.name
          }linkid=${row.partnerLink.id}&clickid={clickid}&payout=${
            row.payout
          }`}</td> */}
          {/* Postback */}
          <td>
            <input
              className="form-control form-control-sm"
              value={row.postback}
              onChange={(e) => onFieldChange(e.target.value, "postback")}
            />
          </td>
          {/* Payout */}
          <td>
            <input
              className="form-control form-control-sm"
              value={row.payout}
              onChange={(e) => onFieldChange(e.target.value, "payout")}
            />
          </td>
          {/* Daily Limit */}
          <td>
            <input
              className="form-control form-control-sm"
              value={row.dailyLimit}
              onChange={(e) => {
                if (+e.target.value >= 0) {
                  onFieldChange(e.target.value, "dailyLimit");
                }
              }}
            />
          </td>
          {/* Restrictions */}
          <td>
            {renderRestrictionEdit({
              label: "Age",
              type: "age",
              inputType: "number",
              onRestrictionChange,
              clearRestriction,
              operators: ageOperators,
              restrictions,
            })}
            {renderRestrictionEdit({
              label: "Geo",
              type: "ipinfo_country",
              inputType: "text",
              onRestrictionChange,
              clearRestriction,
              operators: geoOperators,
              restrictions,
            })}
          </td>
          {/* Currency */}
          {/* <td>
            <MDBSelect
              className="colorful-select dropdown-primary mx-2"
              getTextContent={(val) => onFieldChange(val, "currencyName")}
            >
              <MDBSelectInput selected={row.currencyName} />
              <MDBSelectOptions>
                {currencies.map((currency) => (
                  <MDBSelectOption key={currency.id} value={currency.name}>
                    {currency.name}
                  </MDBSelectOption>
                ))}
              </MDBSelectOptions>
            </MDBSelect>
          </td> */}
          {/* isActive */}
          <td>
            <MDBSelect
              className="colorful-select dropdown-primary mx-2"
              getTextContent={(val) => onFieldChange(val, "isActive")}
            >
              <MDBSelectInput selected={`${row.isActive}`} />
              <MDBSelectOptions>
                {[{ name: "true" }, { name: "false" }].map((action) => (
                  <MDBSelectOption key={action.id} value={action.name}>
                    {action.name}
                  </MDBSelectOption>
                ))}
              </MDBSelectOptions>
            </MDBSelect>
          </td>
          <td>
            <MDBBtn style={{ width: 100 }} color="success" size="sm" onClick={onSavePress}>
              Save
            </MDBBtn>
            <MDBBtn style={{ width: 100 }} color="warning" size="sm" onClick={onTestOpen}>
              Save and test
            </MDBBtn>
            <MDBBtn style={{ width: 100 }} color="danger" size="sm" onClick={onCancel}>
              Cancel
            </MDBBtn>
          </td>
        </tr>
      )}
      
    </React.Fragment>
  );
};

export default TableRow;
