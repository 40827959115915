/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import styled from "styled-components";

import {
  MDBContainer,
  MDBCard,
  MDBBtn,
  MDBIcon,
  MDBTabPane,
  MDBNav,
  MDBNavItem,
  MDBNavLink,
  MDBTabContent,
  MDBInput,
  MDBSpinner,
} from "mdbreact";
import { useSnackbar } from "react-simple-snackbar";

import http from "../../services/http";
import { useSelector } from "react-redux";
import { failureOptions, succesOptions } from "../../configs";

const LinksPage = () => {
  const { choosedSiteId } = useSelector((state) => state.app);
  const [activeTab, setActiveTab] = useState(0);
  const [landings, setLandings] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [openSuccessSnackbar] = useSnackbar(succesOptions);
  const [openFailureSnackbar] = useSnackbar(failureOptions);

  const [newLanding, setNewLanding] = useState("");
  const [types, setTypes] = useState([]);

  useEffect(async () => {
    const { data } = await http.get("/landingrotator/types");
    setTypes(data);
  }, []);

  useEffect(() => {
    getLandings();
  }, [choosedSiteId, activeTab]);

  const getLandings = async () => {
    setIsLoading(true);
    const { data } = await http.get(
      `/landingrotator/${choosedSiteId}/landings?type=${activeTab}`
    );
    setLandings(data?.landings || []);
    setIsLoading(false);
  };

  const addNewLanding = async () => {
    try {
      const { data } = await http.post(
        `/landingrotator/${choosedSiteId}/landings`,
        {
          url: newLanding,
          type: activeTab,
        }
      );
      getLandings();
      openSuccessSnackbar("Added");
      setNewLanding("");
    } catch (err) {
      openFailureSnackbar("Something went wrong.");
    }
  };

  const deleteLanding = async (landingId) => {
    try {
      await http.delete(
        `/landingrotator/${choosedSiteId}/landings/${landingId}`
      );
      getLandings();
      openSuccessSnackbar("Deleted");
    } catch (err) {
      openFailureSnackbar("Something went wrong.");
    }
  };
  return (
    <MDBContainer fluid>
      <MDBCard>
        <Container className="classic-tabs w-100">
          <MDBNav classicTabs color="primary">
            {types.map((type) => (
              <MDBNavItem key={type.value}>
                <MDBNavLink
                  to="#"
                  style={{
                    borderColor: activeTab === type.value ? "white" : "#4285f4",
                  }}
                  onClick={() => {
                    setActiveTab(type.value);
                  }}
                >
                  {type.text}
                </MDBNavLink>
              </MDBNavItem>
            ))}
          </MDBNav>
          <MDBTabContent className="card" activeItem={activeTab}>
            {types.map((type) => (
              <MDBTabPane key={type.value} tabId={type.value}>
                {!isLoading ? <>
                  {landings.map((land) => (
                    <div style={{ display: "flex", alignItems: "flex-end" }}>
                      <div style={{ flex: 1 }}>{land.url}</div>
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: 5,
                          flex: 1,
                        }}
                      >
                        <span>{land.weight}%</span>
                        <div
                          style={{
                            width: 200,
                            height: 20,
                            backgroundColor: "white",
                            border: "1px solid grey",
                            overflow: "hidden",
                          }}
                        >
                          <div
                            style={{
                              width: `${land.weight}%`,
                              height: "100%",
                              backgroundColor: "#4285f4",
                            }}
                          />
                        </div>
                      </div>
                      <div
                        onClick={() => deleteLanding(land.id)}
                        style={{ cursor: "pointer", color: "grey", flex: 1 }}
                      >
                        <MDBIcon icon="trash" />
                      </div>
                    </div>
                  ))}
                </> :  <MDBSpinner className="spin" /> }
              </MDBTabPane>
            ))}
            {/* <h4>Add new link</h4> */}
            <MDBInput
              onChange={(e) => setNewLanding(e.target.value)}
              value={newLanding}
              label="Add new link"
              type="text"
              className="w-50"
            />
            <MDBBtn className="w-50" onClick={addNewLanding}>
              Create
            </MDBBtn>
          </MDBTabContent>
        </Container>
      </MDBCard>
    </MDBContainer>
  );
};

const Container = styled.div`
  & .nav-link.Ripple-parent {
    border-color: red;
  }
  & .nav-link.Ripple-parent.stop.active {
    border-color: green;
  }
  & .spin {
    margin: auto;
  }
`;

export default LinksPage;
