const obj = {
  'admin': {
    'reports': [
      {
        name: 'Statistics',
        url: '/',
      },
      {
        name: 'Dashboard',
        url: '/dashboard',
      },
      {
        name: 'ByRegDate',
        url: '/by-reg-date',
      },
      {
        name: 'All',
        url: '/all',
      },
      {
        name: 'Optimization',
        url: '/optimization',
      }
    ],
    'partners': [
      // {
      //   name: 'Partner stats',
      //   url: '/partners/stats',
      // },
      {
        name: 'ROI report',
        url: '/partners/report5',
      },
      {
        name: 'Partner Restrictions',
        url: '/partners/partner-deals',
      },
      {
        name: 'Partner Webs',
        url: '/partners/partner-webs',
      },
      {
        name: 'Monthly report',
        url: '/monthly-report',
      },
    ],
    'affiliates': [
      {
        name: 'Aff Data',
        url: '/aff-data',
      },
      {
        name: 'Aff Sales',
        url: '/aff-sales',
      },
      {
        name: 'Manual Actions',
        url: '/manual-actions',
      },
      {
        name: 'Curators',
        url: '/curators',
      },
      {
        name: 'Megachat',
        url: '/megachat',
      },
      {
        name: 'Balance',
        url: '/balance',
      },
      {
        name: 'Links',
        url: '/links',
      },
      {
        name: 'Actions',
        url: '/actions',
      },
      {
        name: 'HighRollers',
        url: '/high-rollers',
      },
      {
        name: 'Payments',
        url: '/payments',
      },
    ],
    'users': [
      {
        name: 'All Users',
        url: '/users',
      },
      {
        name: 'Create User',
        url: '/create-user',
      },
    ],
    'sites': [
      {
        name: 'All Sites',
        url: '/sites',
      },
      {
        name: 'Create Site',
        url: '/create-site',
      },
      // {
      //   name: 'Landing rotator',
      //   url: '/landings-rotator'
      // }
    ],
  },
  'manager': {
    'reports': [
      {
        name: 'Statistics',
        url: '/',
      },
      {
        name: 'ByRegDate',
        url: '/by-reg-date',
      },
    ],
    'partners': [
      {
        name: 'ROI report',
        url: '/partners/report5',
      },
      {
        name: 'Partner Restrictions',
        url: '/partners/partner-deals',
      },
      {
        name: 'Partner Webs',
        url: '/partners/partner-webs',
      },
      {
        name: 'Monthly report',
        url: '/monthly-report',
      },
    ],
    // 'sites': [
    //   {
    //     name: 'Landing rotator',
    //     url: '/landings-rotator'
    //   }
    // ]
  },
  'affiliate': {
    'reports': [
      {
        name: 'Statistics',
        url: '/statistics',
      },
      {
        name: 'Report',
        url: '/report',
      },
      {
        name: 'All',
        url: '/all',
      },
    ],
    'affiliates': [
      {
        name: 'Aff Data',
        url: '/aff-data',
      },
      {
        name: 'Aff Sales',
        url: '/aff-sales',
      },
      {
        name: 'Manual Actions',
        url: '/manual-actions',
      },
      {
        name: 'Megachat',
        url: '/megachat',
      },
      // {
      //   name: 'Balance',
      //   url: '/balance',
      // },
      {
        name: 'Links',
        url: '/links',
      },
      {
        name: 'Actions',
        url: '/actions',
      },
      {
        name: 'HighRollers',
        url: '/high-rollers',
      },
      {
        name: 'Payments',
        url: '/payments',
      },
    ],
  },
  'partner': {
    'reports': [
      {
        name: 'Dashboard',
        url: '/',
      },
      {
        name: 'Total',
        url: '/partners/report1',
      },
      {
        name: 'Daily',
        url: '/partners/report2',
      },
    ],
  },
  'default': {
    'reports': [
      {
        name: 'Statistics',
        url: '/',
      },
      {
        name: 'Dashboard',
        url: '/dashboard',
      },
      {
        name: 'ByRegDate',
        url: '/by-reg-date',
      },
      {
        name: 'Partner report 4',
        url: '/partners/report4',
      },
      {
        name: 'Partner report 5',
        url: '/partners/report5',
      },
      {
        name: 'Optimization',
        url: '/optimization',
      }
    ],
  }
}

export default obj;